import request from "@/api/request";

const commandEditApi = {
    //注册命令
    addCommand(data) {
        return request({
            url: '/api/command/add',
            method: 'post',
            data
        })
    },
    //查询命令
    sel(data) {
        return request({
            url: '/api/command/sel',
            method: 'post',
            data
        })
    },
    //查询命令列表
    selCommandForActionEdit(data) {
        return request({
            url: '/api/command/selCommandForActionEdit',
            method: 'post',
            data
        })
    },
    //移除命令标记
    removeMarkById(data){
        return request({
            url: '/api/command/removeMarkById',
            method: 'post',
            data
        })
    },
    //添加标记
    addMark(data){
        return request({
            url: '/api/command/addMark',
            method: 'post',
            data
        })
    },
}

export default commandEditApi