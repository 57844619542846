<!--大数据矢量图-->
<template>
  <div ref="echartsLargeScaleAreaContainer" :style="{width: width, height: height}"></div>
</template>
<script>
import * as echarts from "echarts";
// 引入 Macarons 主题
require('echarts/theme/macarons')
export default {
  name:'dashboardLarge',
  props:{
    width:{
      type: String,
      default: '100%'
    },
    height:{
      type: String,
      default: '100%'
    },
  },
  data(){
    return {
      myChart:null,
      data_large:[],
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.createEcharts()
    })
  },
  watch:{
  data_large(){
      this.updateSpeedDom()
    }
  },
  methods:{
    createEcharts(){
      this.myChart = echarts.init(this.$refs.echartsLargeScaleAreaContainer,'macarons');
      var option;

      let base = 120;
      let item = 1;
      let date = [];

      for (let i = 1; i < base; i++) {
        date.push(item);
        this.data_large.push(0);
        item = item + 1
      }
      option = {
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        toolbox: {

        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: date
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%']
        },
        dataZoom: [

        ],
        series: [
          {
            name: '平均操作次数',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: 'rgb(255, 70, 131)'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(255, 158, 68)'
                },
                {
                  offset: 1,
                  color: 'rgb(255, 70, 131)'
                }
              ])
            },
            data: this.data_large
          }
        ]
      };

      option && this.myChart.setOption(option);
    },
    change(value){
      this.data_large = value
    },
    updateSpeedDom(){
      // 更新ECharts图表的数据
      if (this.myChart) {
        this.myChart.setOption({
          series: [
            {
              name: '平均操作次数',
              type: 'line',
              symbol: 'none',
              sampling: 'lttb',
              itemStyle: {
                color: 'rgb(255, 70, 131)'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(255, 158, 68)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(255, 70, 131)'
                  }
                ])
              },
              data: this.data_large
            }
          ]
        });
      }
    },
  },
}
</script>
<style>

</style>