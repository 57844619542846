<template>
  <div>
    <div v-show="!terminalOnline" style="width: 100%;;height: 30px;background-color: red;margin-top: 0px">
      <h2 style="margin: 0px">终端离线，请确认终端状态</h2>
    </div>
    <!--    <h3>这个是首页</h3>-->
    <!--    <el-button @click="goLogin">点我跳转到登录页</el-button>-->
    <div style="display: flex">
      <div style="height: 25%;width: 20%; display: flex;margin-left: 0px;margin-right: 0px">
        <dashboard ref="dashboard" :height="screenHeight * 0.35 + 'px'" :width="screenWidth * 0.2 + 'px'"></dashboard>
      </div>
      <div style="height: 25%;width: 40%">
        <dashboard-large ref="dashboardLarge" :width="screenWidth * 0.4 + 'px'" :height="screenHeight * 0.35 + 'px'"></dashboard-large>
      </div>
      <div style="height: 100vh;width: 40%;margin-right:3px;margin-left: 0px">
        <el-table :data="terminalDevices" height="100%" style="width: 100%">
          <el-table-column prop="deviceSerialNo" label="设备识别号" min-width="130"></el-table-column>
          <el-table-column prop="deviceName" label="设备名称" min-width="100"></el-table-column>
          <el-table-column prop="status" label="操作占比" min-width="100"></el-table-column>
          <el-table-column label="设备状态" min-width="100">{
            <template slot-scope="scope">
              <el-tag v-show="scope.row.deviceState === 'OFFLINE'" type="info">设备离线</el-tag>
              <el-tag v-show="scope.row.deviceState === 'NOT_STARTED'" type="info">未启动</el-tag>
              <el-tag v-show="scope.row.deviceState === 'RUNNING'" type="success">运行中</el-tag>
              <el-tag v-show="scope.row.deviceState === 'COMPLETED'" type="success">执行完成</el-tag>
              <el-tag v-show="scope.row.deviceState === 'ERROR'" type="danger">异常</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="100">
            <template slot-scope="scope">
              <el-button
                  v-if="scope.row.uphold && (scope.row.deviceState === 'NOT_STARTED' || scope.row.deviceState === 'COMPLETED')"
                  type="success"
                  size="mini"
                  @click="changeWorkThreadStatus(scope.row)" :loading="buttonLoading.startDrviceButton">启动</el-button>
              <el-button
                  v-if="!scope.row.uphold"
                  type="primary"
                  size="mini"
                  @click="addDevice(scope.row)" :loading="buttonLoading.startDrviceButton">添加设备</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

  </div>
</template>
<script>
import {mapState} from "vuex";
import dashboard from "@/components/Echarts/Dashboard.vue";
import dashboardLarge from "@/components/Echarts/LargeScaleArea.vue";
import localTerminalApi from "@/api/local/index"
import DashboardLarge from "@/components/Echarts/LargeScaleArea.vue";
import deviceApi from "@/api/device";
export default {
  name: "Home",
  components: {DashboardLarge, dashboard},
  comments:{
    dashboard,
    dashboardLarge
  },
  computed:{
    ...mapState({
      nickName:(state)=>{
        return state.loginUser.user.nickName
      },
    }),
  },
  watch:{
    //展示欢迎页
    nickName(newValue, oldValue){
      this.$message({
        message: '欢迎您, ' + newValue,
        type: 'success'
      });
    },
    // terminalDevices(newValue,oldValue){
    //   if (this.arrayEq(newValue,oldValue)){
    //     //console.log('列表未发生变化')
    //     return
    //   }
    //   if (this.terminalDevices.length === 0){
    //     return
    //   }
    //   //查询当前用户下已管理的设备列表
    //   deviceApi.selDevices().then((res)=>{
    //     if (res && res.code === 200){
    //       if (res.list.length > 0){
    //         console.log('开始计算设备列表')
    //         for (let i = 0; i < this.terminalDevices.length; i++) {
    //           let deviceSerialNo = this.terminalDevices[i].deviceSerialNo
    //           let upholdDevices = res.list.filter(item => item.deviceSerialNo === deviceSerialNo)
    //           if (upholdDevices.length === 1){
    //             // this.$set(this.terminalDevices[i], 'uphold', 'uphold');
    //             // this.$set(this.terminalDevices[i], 'account', upholdDevices[0].account);
    //             // this.$set(this.terminalDevices[i], 'authKey', upholdDevices[0].authKey);
    //             this.terminalDevices[i].uphold = 'uphold'
    //             this.terminalDevices[i].account = upholdDevices[0].account
    //             this.terminalDevices[i].authKey = upholdDevices[0].authKey
    //           }
    //         }
    //       }
    //     }else {
    //       this.$message.error("获取用户设备列表失败")
    //     }
    //   })
    // },
  },
  mounted(){
    this.getTerminalInfo();
    //this.getNumberOfOperations()
    //this.getLargeScaleArea()
    //this.getDeviceList()
  },
  data() {
    return {
      //当前终端状态
      terminalOnline:true,
      //当前屏幕的宽度
      screenWidth:window.screen.width,
      screenHeight:window.screen.height,
      //接收定时器
      timer_terminalInfo: "",
      //设备列表
      terminalDevices:[],
      buttonLoading:{
        startDrviceButton:false,
      },
    };
  },
  methods: {
    getTerminalInfo(){
      this.timer_terminalInfo = setInterval(()=>{
        let param = {}
        param.account = localStorage.getItem('userAccount');
        localTerminalApi.getTerminalInfo(param).then((res)=>{
          //console.log("getTerminalInfo",res)
          if (res){
            if (res.terminalVersion){
              this.terminalOnline = true
              //设置终端管理的设备列表
              this.terminalDevices = res.deviceInfoList
              //设置操作数度表
              this.$refs.dashboard.changeSpeed(res.numberOfOperations)
              //设置趋势表
              this.$refs.dashboardLarge.change(res.largeScaleArea)
            }else {
              this.terminalOnline = false
            }
          }else {
            console.log("终端信息拉取失败")
            this.terminalOnline = false
          }
        }).catch((e)=>{
          console.log("请求异常。。。 设置为0")
          this.terminalOnline = false
          this.$refs.dashboard.changeSpeed(0)
        })
      },3500)
    },
    // getDeviceListMethod(){
    //   localTerminalApi.getDeviceInfos().then((res)=>{
    //     //console.log('deviceList:',res)
    //     if (this.terminalDevices.length === 0){
    //       this.terminalDevices = res
    //     }else {
    //       if(res && res.length > 0){
    //         //添加新上线的
    //         for (let i = 0; i < res.length; i++) {
    //           let pageDevice = this.terminalDevices.filter(item => item.deviceSerialNo === res[i].deviceSerialNo)
    //           if (pageDevice && pageDevice.length === 0){
    //             this.terminalDevices.push(res[i])
    //           }
    //         }
    //         let downDevice = []
    //         //移除已下线的设备
    //         for (let i = 0; i <this.terminalDevices.length; i++) {
    //           let targerItem = res.filter(item => item.deviceSerialNo === this.terminalDevices[i].deviceSerialNo);
    //           if (!targerItem || targerItem.length === 0){
    //             downDevice.push(this.terminalDevices[i].deviceSerialNo)
    //           }
    //         }
    //         if (downDevice.length > 0){
    //           this.terminalDevices = this.terminalDevices.filter(item => !downDevice.includes(item.deviceSerialNo))
    //         }
    //         //更新设备线程运行状态
    //         for (let i = 0; i <this.terminalDevices.length; i++) {
    //           let itemStatus = res.filter(item => (item.deviceSerialNo === this.terminalDevices[i].deviceSerialNo && item.workThread.threadInfo.state !== this.terminalDevices[i].workThread.threadInfo.state));
    //           if (itemStatus && itemStatus.length > 0){
    //             this.terminalDevices[i].workThread.threadInfo.state = itemStatus[0].workThread.threadInfo.state
    //           }
    //         }
    //       }
    //     }
    //     this.terminalOnline = true
    //   }).catch((res)=>{
    //     this.terminalOnline = false
    //     console.log("当前终端管理的设备列表加载失败!")
    //   })
    // },
    arrayEq(array1,array2){
      if (array1.size !== array2.size) {
        return false;
      }
      for (let item of array1) {
        let deviceSerialNos = array2.filter(child => child.deviceSerialNo === item.deviceSerialNo)
        if (deviceSerialNos && deviceSerialNos.length === 0) {
          return false;
        }
      }
      return true;
    },
    changeWorkThreadStatus(row){
      //console.log('device:',row)
      this.buttonLoading.startDrviceButton = true
      if(!row.uphold){
        this.$message.error('当前设备未绑定当前账号')
        this.buttonLoading.startDrviceButton = false
        return false
      }
      if (!row.authKey){
        this.$message.error('您未对当前设备设置秘钥，请先设置秘钥')
        this.buttonLoading.startDrviceButton = false
        return false
      }
      let paramAuth = {}
      paramAuth.account = localStorage.getItem('userAccount')
      paramAuth.authKey = row.authKey
      paramAuth.deviceSerialNo = row.deviceSerialNo
      paramAuth.deviceIp = row.deviceIp
      //触发终端任务
      deviceApi.authGet(paramAuth).then((res)=>{
        //console.log('authGet：',res)
        if (res && res.code === 'error'){
          this.$message.error(res.msg)
        }else {
          this.$message.success("命令下发到终端成功")
        }
        this.buttonLoading.startDrviceButton = false
      })
    },
    addDevice(row) {
      console.log("row:",row)
      let param = {}
      param.deviceSerialNo = row.deviceSerialNo
      param.deviceName = row.deviceName
      param.deviceIp = row.deviceIp
      param.authKey = 'authKey'
      param.account = localStorage.getItem('userAccount')
      deviceApi.addDevice(param).then((res)=>{
        if (res && res === 200){
          this.$message.success("设备添加成功");
        }else {
          this.$message.error("设备添加失败");
        }
      })
    },
  },
  beforeDestroy() {
    //清除定时器
    clearInterval(this.timer_terminalInfo);
    //清理设备列表
    this.terminalDevices = []
    //console.log("beforeDestroy");
  },
  destroyed() {

  }
};
</script>
<style>
</style>