import request from "@/api/request";

const fileApi = {
    //添加模版
    uploadTemplate(form) {
        return request({
            url: '/public/uploadFile',
            method: 'post',
            data: form,
        })
    },
}

export default fileApi