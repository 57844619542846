<template>
  <div>
    <!-- <img :src="imageUrl" /> -->
    <div class="demo-image">
      <div class="block">
        <el-image
          style="width: 100px; height: 100px"
          :src="imageUrl"
          :preview-src-list="srcPic"
          fit="contain"
        ></el-image>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImageView",
  props: {
    fileUrl: {
      type: String,
      require: true,
    },
  },
  created() {
    //加载图像
    this.init();
  },
  watch: {
    fileUrl() {
      console.log("fileUrl发生变化");
      this.init();
    },
  },
  data() {
    return {
      imageUrl: "",
      srcPic:[],
    };
  },
  methods: {
    //加载动作模版图像
    init() {
      //console.log('子组件方法触发:',this.fileId)
      this.imageUrl = this.fileUrl;
      this.srcPic = this.fileUrl; //大图预览
    },
  },
};
</script>
<style scoped>
</style>