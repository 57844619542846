import request from "@/api/request";


const menuApi = {
    createMenu(data){
        return request({
            url: '/api/menu/createMenu',
            method: 'post',
            data
        })
    },
    //查询所有列表
    getMenuList(data){
        return request({
            url: '/api/menu/getMenuList',
            method: 'post',
            data
        })
    },
    //查询用户列表
    queryMenus(){
        return request({
            url: '/api/menu/getMenus',
            method: 'post',
        })
    },
    changeState(data){
        return request({
            url: '/api/menu/changeState',
            method: 'post',
            data
        })
    },
    changeOrderKey(data){
        return request({
            url: '/api/menu/changeOrderKey',
            method: 'post',
            data
        })
    },
    remove(data){
        return request({
            url: '/api/menu/remove',
            method: 'post',
            data
        })
    },
}

export default menuApi