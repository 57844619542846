<template>
  <div class="container">
    <el-form class="form-container" :model="loginForm" :rules="rules" ref="loginForm">
      <h2>智慧云管</h2>
      <div style="display: flex; flex-direction: column">
        <el-form-item prop="account">
          <el-input
              v-model.trim="loginForm.account"
              style="width: 350px"
              placeholder="用户名"
          >
            <template slot="prepend"
            ><i class="el-icon-user-solid"></i
            ></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              style="width: 350px"
              placeholder="密码"
              type="password"
              v-model.trim="loginForm.password"
          >
            <template slot="prepend"><i class="el-icon-lock"></i></template>
          </el-input>
        </el-form-item>

        <div style="display: flex;justify-content: flex-start">
          <el-form-item prop="captchaCode">
            <el-input
                style="width: 210px;margin-left: 0px"
                placeholder="验证码"
                maxlength="4"
                v-model.trim="loginForm.captchaCode"
            >
              <template slot="prepend"><i class="el-icon-place"></i></template>
            </el-input>
          </el-form-item>
          <el-image
              @click="changeUrl"
              style="width: 100px; height: 40px;margin-left: 20px"
              :src="captchaCodeImage"
              fit="contain"></el-image>
        </div>

      </div>
      <el-button
          type="success"
          round
          style="width: 350px"
          @click="loginMethod"
          :loading="loginButtonLoading"
      >登录</el-button
      >
    </el-form>
  </div>
</template>
<script>
import logApi from "@/api/login";
import {CaptchaImg_url} from "@/constants/constants";
export default {
  name: "Login",
  data() {
    return {
      //登录按钮状态
      loginButtonLoading: false,
      //表单校验
      rules: {
        account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 10,
            message: "密码长度应大于6个字符",
            trigger: "blur",
          },
        ],
        captchaCode:[{required: true, message: "请输入验证码", trigger: "blur"}],
      },
      captchaCodeImage:CaptchaImg_url,
      loginForm: {
        account: "",
        password: "",
        captchaCode: "",
      },
    };
  },

  methods: {
    //刷新验证码
    changeUrl(){
      let url = this.captchaCodeImage.substring(0,this.captchaCodeImage.length-1)
      this.captchaCodeImage = url + Math.floor(Math.random() * 10)
    },
    async loginMethod() {
      this.$refs['loginForm'].validate((valid)=>{
        if (valid){
          this.loginButtonLoading = true
          logApi.login(this.loginForm).then((res) => {
            //console.log("@loginRes", res);
            if (res && res.loginCode === 10000) {
              this.loginButtonLoading = false
              //登录成功，token保存到localStorage
              localStorage.setItem("Authorization", res.token);
              //登录成功，保存当前登录用户到localStorage
              localStorage.setItem('userAccount', res.account);
              //拉取用户完整数据，如果拉取成功，那么跳转到home页面
              //将完整用户数据保存到vuex
              //console.log("VueX:", this.$store);
              this.$store.dispatch("loginUserToStore");
              //跳转到主页
              this.$router.push("home");
            } else if (res && res.loginCode === 10002) {
              this.loginButtonLoading = false
              this.$message.error(res.loginMsg);
            } else {
              console.log('res',res)
              this.$message({
                message: res.loginMsg,
                type: "warning",
              });
              this.loginButtonLoading = false
            }
          }).catch((e)=>{
            console.log('登录异常：',e)
            this.loginButtonLoading = false
          })
        }else {
          this.$message.error('请完善登录信息')
        }
      })
    },
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(~@/assets/login/background_11.jpg) no-repeat center center
  fixed;
  /* background-color: rgb(234, 236, 239); */
  background-size: 100% 100%;
}

.form-container {
  /* 根据需要调整el-form的样式 */
  margin: 0; /* 重置边距 */
  /* 其他样式 */
}
h2 {
  color: white; /* 半透明的蓝色 */
}
</style>
