import request from "@/api/request";


const roleApi = {
    //创建角色
    createRole(data){
        return request({
            url: '/api/role/createRole',
            method: 'post',
            data
        })
    },
    remove(data){
        return request({
            url: '/api/role/remove',
            method: 'post',
            data
        })
    },
    //查询全部角色信息
    queryRoles(data){
        return request({
            url: '/api/role/getRoles',
            method: 'post',
            data
        })
    },
    //查询用户角色信息
    queryUserRole(){
        return request({
            url: '/api/role/getUserRole',
            method: 'post',
        })
    },
    //查询角色关联的菜单
    queryMenusByRoleId(data){
        return request({
            url: '/api/role/queryMenusByRoleId',
            method: 'post',
            data
        })
    },
}

export default roleApi