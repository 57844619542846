<template>
  <div class="container">
    <!--      查询框-->
    <div style="margin:20px">
      <el-form :inline="true">
        <el-form-item label="角色名称">
          <el-input v-model="search.roleName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryRoleList">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="createRole.showCreateDialog = true">新增角色</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="width: 40%;margin: 0 auto">
      <el-table :data="roleList" stripe style="width: 100%">
        <el-table-column
            prop="roleName"
            label="角色名称"
            width="180">
        </el-table-column>
        <el-table-column
            prop="createUser"
            label="创建用户"
            width="180">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            width="200">
          <template slot-scope="scope">
            {{parseTime(scope.row.createTime)}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editRole(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="remove(scope.row)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="角色编辑" :visible="createRole.showCreateDialog" width="35%" :before-close="handleClose">
      <el-form ref="form" :model="createRole" label-width="80px">
        <el-form-item label="角色名称:" style="width: 90%">
          <el-input v-model="createRole.roleName"></el-input>
        </el-form-item>
        <el-form-item label="关联菜单:">
          <el-checkbox-group v-model="createRole.menuIdList" style="width: 90%">
            <el-checkbox v-for="item in menuList" :key="item.menuId" :label="item.menuId">{{item.menuName}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div>
          <el-button type="primary" size="medium" @click="createRoleMethod">保存角色</el-button>
        </div>

      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import roleApi from "@/api/role";
import util from "@/util";
import menuApi from "@/api/menu";
export default {
  name:'RoleAuth',
  data(){
    return {
      roleList:[],
      menuList:[],
      search:{
        roleName:'',
      },
      createRole:{
        showCreateDialog:false,
        roleId:0,
        roleName:'',
        menuIdList:[],
      },
    }
  },
  mounted() {
    //加载角色列表
    this.queryRoleList();
    //加载所有菜单
    this.queryMenuList();
  },
  methods:{
    parseTime(val){
      return util.parseTime(val)
    },
    queryRoleList(){
      let param = {}
      param.roleName = this.search.roleName
      roleApi.queryRoles(param).then((res)=>{
        //console.log('queryRoles',res)
        this.roleList = res
      })
    },
    queryMenuList(){
      let param = {}
      menuApi.getMenuList(param).then((res)=>{
        console.log('queryMenus',res)
        this.menuList = res
      })
    },
    createRoleMethod(){
      //console.log(this.createRole)
      let param = {}
      param.roleId = this.createRole.roleId
      param.roleName = this.createRole.roleName
      param.menuIdList = this.createRole.menuIdList
      roleApi.createRole(param).then((res)=>{
        if (res && res == 200){
          this.$message.success("创建成功")
          this.queryRoleList();
          this.handleClose();
        }else {
          this.$message.error("创建失败")
        }
      })
    },
    editRole(val){
      //查询角色关联的菜单信息
      let param = {}
      param.roleId = val.roleId
      roleApi.queryMenusByRoleId(param).then((res)=>{
        //console.log('queryMenusByRoleId',res)
        this.createRole.menuIdList = res
        this.createRole.roleId = val.roleId
        this.createRole.roleName = val.roleName
        this.createRole.showCreateDialog = true
      })
    },
    remove(val){
      let param = {}
      param.roleId = val.roleId
      roleApi.remove(param).then((res)=>{
        if (res && res == 200){
          this.$message.success("移除成功")
          this.queryRoleList();
        }else {
          this.$message.error("移除失败")
        }
      })
    },
    handleClose(){
      this.createRole = {
        showCreateDialog:false,
        roleId:0,
        roleName:'',
        menuIdList:[],
      }
    }
  },

}
</script>
<style>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>