<template>
  <div class="centerDiv">
    <el-card class="box-card" style="width: 100%; margin-top: 10px">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="命令关键字">
          <el-input
              v-model="searchForm.commandKey"
              placeholder="请输入命令关键字"
          ></el-input>
        </el-form-item>
        <el-form-item></el-form-item>
        <el-form-item label="命令名称">
          <el-input
              v-model="searchForm.commandNameKey"
              placeholder="请输入命令名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchList">查询</el-button>
          <el-button @click="addCommandDialog = true">新增命令</el-button>
        </el-form-item>
      </el-form>
      <el-table
          :data="commandList"
          border
          width="100%"
          style="margin-top: 20px"
      >
        <el-table-column prop="commandName" label="名称"> </el-table-column>
        <el-table-column prop="command" label="命令"> </el-table-column>
        <el-table-column prop="commandDec" label="描述"> </el-table-column>
        <el-table-column prop="type" label="命令类型">
          <template slot-scope="scope">
            <el-tag v-if = "scope.row.type === 'FIXED'" type="warning">固定命令</el-tag>
            <el-tag v-else-if="scope.row.type === 'POINT'" type="warning">坐标命令</el-tag>
            <el-tag v-else-if="scope.row.type === 'TEMPLATE'">模版命令</el-tag>
            <el-tag v-else-if="scope.row.type === 'TEXT_RECOGNITION'" type="info">文本命令</el-tag>
            <el-tag v-else type="danger">未知命令类型</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态"> </el-table-column>
        <el-table-column label="标记">
          <template slot-scope="scope">
            <el-row>
              <!--成功标记-->
              <el-col
                  v-show="
                  scope.row.successMarkList &&
                  scope.row.successMarkList.length > 0
                "
              >
                <el-tag
                    v-for="(item, index) in scope.row.successMarkList"
                    :key="index"
                    style="margin: 2px"
                    type="success"
                >{{ item.mark }}</el-tag
                >
              </el-col>
              <!--失败标记-->
              <el-col
                  v-show="
                  scope.row.failMarkList && scope.row.failMarkList.length > 0
                "
              >
                <el-tag
                    v-for="(item, index) in scope.row.failMarkList"
                    :key="index"
                    style="margin: 2px"
                    type="danger"
                >{{ item.mark }}</el-tag
                >
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="createUser" label="创建用户"> </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{ parseTime(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                size="medium"
                type="primary"
                @click="editCommandForm(scope.row)"
            >基本信息</el-button
            >
            <el-button
                size="medium"
                type="warning"
                @click="editMarkForm(scope.row)"
            >标签</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!--新增命令Dialog-->
    <el-dialog
        title="新增ADB命令"
        :visible="addCommandDialog"
        width="40%"
        :before-close="handleClose"
    >
      <el-form ref="commandForm" :model="commandForm" label-width="100px">
        <el-row>
          <el-col :span="15">
            <el-form-item label="命令">
              <el-input v-model="commandForm.command">
                <template slot="prepend">ADB</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6"
          ><el-switch
              style="display: block"
              v-model="commandForm.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="启用"
              inactive-text="禁用"
          >
          </el-switch
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="15">
            <el-form-item label="名称">
              <el-input v-model="commandForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="命令描述">
              <el-input
                  v-model="commandForm.commandDec"
                  type="textarea"
                  :rows="2"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="角色权限">
              <el-select v-model="commandForm.rule" placeholder="请选择">
                <el-option
                    v-for="item in ruleOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="命令类型">
              <el-select v-model="commandForm.type" placeholder="请选择">
                <el-option
                    v-for="item in typeOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div>
            <el-button type="primary" style="width: 70%" @click="createCommand"
            >保存命令信息</el-button
            >
          </div>
        </el-row>
      </el-form>
    </el-dialog>
    <el-drawer
        :title="markForm.drawerTitle"
        :visible="markForm.drawerView"
        direction="btt"
        :before-close="drawerHandleClose"
    >
      <el-row>
        <el-tag
            :key="tag.id"
            v-for="tag in markForm.adbCommandDto.successMarkList"
            closable
            :disable-transitions="false"
            @close="removeTag(tag)"
            style="margin: 5px"
            type="success"
        >
          {{ tag.mark }}
        </el-tag>
        <el-input
            class="input-new-tag"
            v-if="markForm.inputSuccessMarkVisible"
            v-model="markForm.inputValue"
            ref="saveSuccessMarkTagInput"
            size="small"
            @keyup.enter.native="handleSuccessMarkInputConfirm"
            @blur="handleSuccessMarkInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showSuccessMarkInput"
        >+ New Tag</el-button
        >
      </el-row>
      <el-row>
        <el-tag
            :key="tag.id"
            v-for="tag in markForm.adbCommandDto.failMarkList"
            closable
            :disable-transitions="false"
            @close="removeTag(tag)"
            style="margin: 5px"
            type="danger"
        >
          {{ tag.mark }}
        </el-tag>
        <el-input
            class="input-new-tag"
            v-if="markForm.inputFailMarkVisible"
            v-model="markForm.inputValue"
            ref="saveFailMarkTagInput"
            size="small"
            @keyup.enter.native="handleFailMarkInputConfirm"
            @blur="handleFailMarkInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showFailMarkInput"
        >+ New Tag</el-button
        >
      </el-row>
    </el-drawer>
  </div>
</template>
<script>
import commandEditApi from "@/api/commandEdit";
import util from "@/util";
export default {
  name: "CommandEdit",
  mounted() {
    this.queryList();
  },
  data() {
    return {
      //新增命令的弹框
      addCommandDialog: false,
      //查询的form
      searchForm: {
        commandKey: "",
        commandNameKey: "",
      },
      //注册的form
      commandForm: {
        id:'',
        command: "",
        name: "",
        commandDec: "",
        rule: "",
        type: "",
        status: true,
      },
      ruleOption: [
        { value: "admin", label: "超级管理员" },
        { value: "other", label: "其他用户" },
      ],
      typeOption: [
        { value: "FIXED", label: "固定命令" },
        { value: "POINT", label: "坐标命令" },
        { value: "TEMPLATE", label: "模版命令" },
        { value: "TEXT_RECOGNITION", label: "文本命令" },
      ],
      //命令列表
      commandList: [],
      //标记mark编辑
      markForm: {
        drawerView: false,
        drawerTitle: "",
        inputSuccessMarkVisible: false,
        inputFailMarkVisible: false,
        inputValue: "",
        adbCommandDto: {},
      },
    };
  },
  methods: {
    //加载命令列表
    queryList() {
      let param = {}
      commandEditApi.sel(param).then((res) => {
        //console.log('res:',res);
        if (res && res.code === 200) {
          this.commandList = res.list;
        } else {
          this.$message.error("命令列表加载失败");
        }
      });
    },
    //过滤命令集合
    searchList() {
      console.log("submit!");
    },
    createCommand() {
      let param = {};
      param.id = this.commandForm.id
      param.command = this.commandForm.command;
      param.commandName = this.commandForm.name;
      param.commandDec = this.commandForm.commandDec;
      param.role = this.commandForm.rule;
      param.type = this.commandForm.type
      param.status = this.commandForm.status ? "Enable" : "Disable";

      commandEditApi.addCommand(param).then((res) => {
        if (res.code === 200) {
          this.handleClose();
          this.queryList();
          this.$message({
            type: "success",
            message: res.msg,
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClose() {
      this.addCommandDialog = false;
      this.commandForm = {
        id:'',
        command: "",
        name: "",
        commandDec: "",
        rule: "",
        type:'',
        status: true,
      };
    },
    //编辑基本信息
    editCommandForm(row) {
      console.log(row)
      this.commandForm.id = row.id
      this.commandForm.command = row.command;
      this.commandForm.name = row.commandName;
      this.commandForm.commandDec = row.commandDec;
      this.commandForm.rule = row.role;
      this.commandForm.type = row.type;
      this.commandForm.status = row.status === "Enable" ? true : false;

      this.addCommandDialog = true;
    },
    //编辑标记列表
    editMarkForm(row) {
      console.log(row);
      this.markForm.adbCommandDto = row;
      this.markForm.drawerTitle = row.command;
      this.markForm.drawerView = true;
    },
    drawerHandleClose() {
      this.markForm.drawerView = false;
      this.markForm = {
        drawerView: false,
        drawerTitle: "",
        adbCommandDto: {},
      };
    },
    removeTag(tag) {
      console.log("-:", tag);
      commandEditApi.removeMarkById(tag).then((res)=>{
        console.log('res:',res);
        if(res && res.code === 200){
          if(tag.type === 'success'){
            this.markForm.adbCommandDto.successMarkList = res.list
          }else{
            this.markForm.adbCommandDto.failMarkList = res.list
          }
        }
      })
    },
    showSuccessMarkInput() {
      this.markForm.inputSuccessMarkVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveSuccessMarkTagInput.$refs.input.focus();
      });
    },
    showFailMarkInput() {
      this.markForm.inputFailMarkVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveFailMarkTagInput.$refs.input.focus();
      });
    },
    handleSuccessMarkInputConfirm() {
      let inputValue = this.markForm.inputValue;
      let param = {}
      param.commandId = this.markForm.adbCommandDto.id
      param.type = 'success'
      param.mark = inputValue
      commandEditApi.addMark(param).then((res)=>{
        //console.log('res',res)
        if (res && res.code === 200){
          this.markForm.adbCommandDto.successMarkList = res.list
        }
      })
      this.markForm.inputSuccessMarkVisible = false;
      this.markForm.inputValue = "";
    },
    handleFailMarkInputConfirm() {
      let inputValue = this.markForm.inputValue;
      let param = {}
      param.commandId = this.markForm.adbCommandDto.id
      param.type = 'fail'
      param.mark = inputValue
      commandEditApi.addMark(param).then((res)=>{
        //console.log('res',res)
        if (res && res.code === 200){
          this.markForm.adbCommandDto.failMarkList = res.list
        }
      })
      this.markForm.inputFailMarkVisible = false;
      this.markForm.inputValue = "";
    },
    parseTime(val) {
      return util.parseTime(val);
    },
  },
};
</script>
<style>
.centerDiv {
  width: 98vw;
  height: 98vh;
  margin: 0 auto;
}
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 480px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>