<template>
  <div class="container">
    <!--      查询框-->
    <div style="margin:20px">
      <el-form :inline="true">
        <el-form-item label="菜单名称">
          <el-input v-model="search.menuName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryMenuList">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="createMenu.showCreateDialog = true">新增菜单</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="width: 60%;margin: 0 auto">
      <el-table :data="menuList" stripe style="width: 100%">
        <el-table-column
            prop="menuName"
            label="菜单名称"
            width="200">
        </el-table-column>
        <el-table-column
            prop="createUser"
            label="创建用户"
            width="180">
        </el-table-column>
        <el-table-column
            prop="orderKey"
            label="排序Key"
            width="180">
          <template slot-scope="scope">
            <el-button type="text" size="medium" @click="editOrderKeyMethod(scope.row)">{{scope.row.orderKey}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
            label="状态"
            width="180">
          <template slot-scope="scope">
            <el-button v-if="scope.row.state === 'ENABLE'" type="success" size="mini" @click="changeState(scope.row,'DISABLE')">已启用</el-button>
            <el-button v-if="scope.row.state === 'DISABLE'" type="danger" size="mini" @click="changeState(scope.row,'ENABLE')">已禁用</el-button>
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            width="200">
          <template slot-scope="scope">
            {{parseTime(scope.row.createTime)}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editMenu(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="removeMenu(scope.row)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="菜单编辑" :visible="createMenu.showCreateDialog" width="35%" :before-close="handleClose">
      <el-form ref="form" :model="createMenu" label-width="80px">
        <el-form-item label="菜单名称:" style="width: 90%">
          <el-input v-model="createMenu.menuName"></el-input>
        </el-form-item>
        <el-form-item label="菜单路径:" style="width: 90%">
          <el-input v-model="createMenu.menuUrl"></el-input>
        </el-form-item>
        <div>
          <el-button size="medium" type="primary" @click="createMenuMethod">保存</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="调整菜单序号" :visible="editOrderKey.showEditOrderKeyDialog" width="20%" :before-close="handleEditOrderKeyClose">
      <div>
        <el-input-number v-model="editOrderKey.orderKey" :min="100" :max="200"></el-input-number>
      </div>
      <div>
        <el-button type="primary" size="medium" style="margin-top: 20px" @click="saveOrderKey">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import util from "@/util";
import menuApi from "@/api/menu";
export default {
  name:'MenuAuth',
  data(){
    return {
      menuList:[],
      search:{
        menuName:'',
      },
      createMenu:{
        showCreateDialog:false,
        menuId:0,
        menuName:'',
        menuUrl:'',
      },
      editOrderKey:{
        showEditOrderKeyDialog:false,
        menuId:0,
        orderKey:0,
      },
    }
  },
  mounted() {
    //加载菜单列表
    this.queryMenuList();
  },
  methods:{
    parseTime(val){
      return util.parseTime(val)
    },
    queryMenuList(){
      let param = {}
      param.menuName = this.search.menuName
      menuApi.getMenuList(param).then((res)=>{
        //console.log('queryMenus',res)
        this.menuList = res
      })
    },
    createMenuMethod(){
      let param = {}
      param.menuId = this.createMenu.menuId;
      param.menuName = this.createMenu.menuName;
      param.menuUrl = this.createMenu.menuUrl;

      menuApi.createMenu(param).then((res)=>{
        //console.log(res)
        if (res && res == 200){
          this.$message.success("创建成功")
          this.queryMenuList();
          this.handleClose();
        }else {
          this.$message.error("创建失败")
        }
      })
    },
    editMenu(val){
      this.createMenu.menuId = val.menuId
      this.createMenu.menuName = val.menuName
      this.createMenu.menuUrl = val.menuUrl
      this.createMenu.showCreateDialog = true
    },
    removeMenu(val){
      let param = {}
      param.menuId = val.menuId
      menuApi.remove(param).then((res)=>{
        if (res && res == 200){
          this.$message.success("菜单移除成功")
          this.queryMenuList();
        }else {
          this.$message.error("菜单移除失败")
        }
      })
    },
    changeState(val,state){
      let param = {}
      param.menuId = val.menuId
      param.state = state
      menuApi.changeState(param).then((res)=>{
        if (res && res == 200){
          this.$message.success("菜单状态重置成功")
          this.queryMenuList();
        }else {
          this.$message.error("菜单状态重置失败")
        }
      })
    },
    editOrderKeyMethod(row){
      this.editOrderKey.showEditOrderKeyDialog = true
      this.editOrderKey.menuId = row.menuId
      this.editOrderKey.orderKey = row.orderKey
    },
    saveOrderKey(){
      console.log(this.editOrderKey)
      let param = {}
      param.menuId = this.editOrderKey.menuId
      param.orderKey = this.editOrderKey.orderKey

      menuApi.changeOrderKey(param).then((res)=>{
        if (res && res == 200){
          this.$message.success("菜单序号调整成功")
          this.queryMenuList();
          this.handleEditOrderKeyClose();
        }else {
          this.$message.error("菜单序号调整失败")
        }
      })
    },
    handleClose(){
      this.createMenu = {
        showCreateDialog:false,
        menuId:0,
        menuName:'',
        menuUrl:'',
      }
    },
    handleEditOrderKeyClose(){
      this.editOrderKey = {
        showEditOrderKeyDialog:false,
        menuId:0,
        orderKey:0,
      }
    },
  },

}
</script>
<style>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>