//引入axios
import axios from "axios";

//引入进度条
import nprogress from 'nprogress'

//引入进度条样式
import 'nprogress/nprogress.css'

//引入加解密工具
import CryptoJS from 'crypto-js'

//参数加密及解密的秘钥
const secretKey = 'adbProject-Last-hero'


//创建axios实例，并设置基本参数
const requests = axios.create({
    baseURL: "/",
    timeout: 10000
})


//请求拦截器
//将token封装进请求头
requests.interceptors.request.use((config) => {
    //网络请求开始，开启进度条
    nprogress.start()

    //对post方法请求体进行加密(暂缓开发)
    // if (config.method && config.method === 'post'){
    //     // 将数据转换为 JSON 字符串（如果它不是字符串的话）
    //     let dataToEncrypt = typeof config.data === 'string' ? config.data : JSON.stringify(config.data);
    //     config.data = encrypt(dataToEncrypt)
    // }
    // // 获取并打印请求信息
    // console.log('请求方法:', config.method);
    // console.log('请求 URL:', config.url);
    // console.log('请求数据体:', config.data); // 注意：对于 GET 请求，这里可能是 undefined
    // console.log('URL 参数:', config.params);

    //config是配置对象，当中的header对象很重要
    const token = localStorage.getItem('Authorization')
    if (token) {
        // 如果token存在，则将token添加到请求头中  
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config
})

//响应拦截器
requests.interceptors.response.use(
    (res) => {
        //网络请求结束，关闭进度条
        nprogress.done()
        //服务器成功的回调函数
        return res.data
    },
    (err) => {
        //网络请求结束，关闭进度条
        nprogress.done()

        //console.log('err:', err.response.status)
        //redis服务器超时处理，目前只是发现status为空，所以这个位置只拦截一下status
        console.log('err',err)
        if (err.response && err.response.status) {
            //服务器响应失败的回调函数
            if (err.response.status === 401) {
                console.log('401，重定向到login')
                //清除token， 不然这个位置即使个死循环
                localStorage.removeItem('Authorization')
                //暂时先这么写，可以使用，之后考虑为什么router不行
                window.location.href = "/login";
            } else {
                // 其他错误处理逻辑
                return Promise.reject(err);
            }
        } else {
            console.log('@Redis error !!!');
            return false
        }
    })

// 加密
function encrypt(data) {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
}

// 解密
function decrypt(encryptedData) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
}

//对外暴露配置好的axios
export default requests