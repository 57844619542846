import request from "@/api/request";

const jobListApi = {
    //注册新任务
    registerJob(data) {
        return request({
            url: '/api/job/register',
            method: 'post',
            data
        })
    },
    //查询任务列表
    queryJobList(data) {
        return request({
            url: '/api/job/selJobList',
            method: 'post',
            data
        })
    },
    repairJobInfo(data){
        return request({
            url: '/api/job/repairJobInfo',
            method: 'post',
            data
        })
    },
    //修改任务状态
    updateJobStatus(data) {
        return request({
            url: '/api/job/updateJobStatus',
            method: 'post',
            data
        })
    },
    //移除指定任务
    remove(data) {
        return request({
            url: '/api/job/remove',
            method: 'post',
            data
        })
    },
    getImageStream(data){
        return request({
            url: '/api/file/getImageStream',
            method: 'post',
            data
        })
    },
    
    //---------------------------------------------------动作相关--------------------------------------------------------
    //查询动作列表
    selActionByJobId(data) {
        return request({
            url: '/api/action/selActionByJobId',
            method: 'post',
            data
        })
    },
    //移除定制
    removeJobAction(data){
        return request({
            url: '/api/action/removeJobAction',
            method: 'post',
            data
        })
    },
    //添加动作
    createJobAction(data) {
        return request({
            url: '/api/action/createJobAction',
            method: 'post',
            data
        })
    },
    //添加模版
    uploadTemplate(form) {
        return request({
            url: '/api/action/uploadTemplate',
            method: 'post',
            data: form,
        })
    },
}

export default jobListApi