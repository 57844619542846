<template>
  <div ref="echartsDashboardContainer" :style="{width: width, height: height}"></div>
</template>
<script>
import * as echarts from "echarts";
// 引入 Macarons 主题
require('echarts/theme/macarons')
export default {
  name:'dashboard',
  props:{
    width:{
      type: String,
      required: true
    },
    height:{
      type: String,
      required: true
    },
  },
  data(){
    return {
      myChart:null,
      speed:0,
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.createEcharts()
    })
  },
  watch:{
    speed(){
      this.updateSpeedDom()
    }
  },
  methods:{
    createEcharts(){
      this.myChart = echarts.init(this.$refs.echartsDashboardContainer,'macarons');
      var option;

      option = {
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%'
        },
        series: [
          {
            name: 'Pressure',
            type: 'gauge',
            progress: {
              show: true
            },
            detail: {
              valueAnimation: true,
              formatter: '{value}'
            },
            data: [
              {
                value: this.speed,
                name: '每分钟操作次数'
              }
            ]
          }
        ]
      };
      option && this.myChart.setOption(option);
    },
    changeSpeed(speed){
      this.speed = speed
    },
    updateSpeedDom(){
      // 更新ECharts图表的数据
      if (this.myChart) {
        this.myChart.setOption({
          series: [{
            data: [{
              value: this.speed,
              name:'每分钟操作次数'
            }]
          }]
        });
      }
    },
  },
}
</script>
<style>

</style>