import request from "@/api/request";

const localApi = {
    //获取当前终端信息
    getTerminalInfo(data){
        return request({
            url: '/api/terminal/info',
            method: 'post',
            data
        })
    },
    //获取本地指定设备屏幕镜像
    screen(deviceSerialNo){
        return request({
            url: '/api/terminal/getScreen/'+ deviceSerialNo,
            responseType: 'blob', // 重要：设置响应类型为blob
            method: 'get',
        })
    },
}

export default localApi