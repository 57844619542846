<template>
  <div class="top-bar">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      style="display: flex;justify-content: center;align-items: center"
      mode="horizontal"
      @select="handleSelect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      router
    >
      <el-menu-item index="home">工作台</el-menu-item>
      <el-menu-item v-for="(item,index) in menuList" :key="index" :index="item.menuUrl">{{item.menuName}}</el-menu-item>
    </el-menu>
  </div>
</template>
<script>
import menuApi from "@/api/menu";

export default {
  name: "Header",
  data() {
    return {
      //默认激活的页面
      activeIndex: "home",
      //授权列表
      menuList:[],
    };
  },
  mounted(){
    //加载用户权限
    this.initUserAuth()
  },
  methods: {
    initUserAuth(){
      menuApi.queryMenus().then((res)=>{
        console.log('queryMenus',res)
        this.menuList = res
      }).catch((e)=>{
        this.$message.error('用户菜单加载异常')
      })
      console.log('@initUserAuth');
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style scoped>
.top-bar{
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9000;
}
</style>